<template>
  <div class="">
    <el-form
      class="add-form"
      @submit.prevent="checkChargePackageInformation"
      @keydown.enter.prevent="() => {}"
      :model="chargePackage"
      label-position="top"
      ref="chargePackageForm"
    >
      <div class="form-group-wrapper">
        <!-- Name - Description-->

        <!-- Name -->
        <el-form-item
          v-if="!onlyCharges"
          class="form-group-wrapper-item h3 w-100"
          :label="`${$t('COMMON.NAME')} (*)`"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.NAME_REQUIRED'),
            },
          ]"
        >
          <el-input
            :placeholder="$t('COMMON.NAME')"
            v-model="chargePackage.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </el-form-item>

        <!-- Excerpt -->
        <el-form-item
          v-if="!onlyCharges"
          class="form-group-wrapper-item h3 w-100"
          :label="`${$t('COMMON.EXCERPT')} (*)`"
          prop="excerpt"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            :placeholder="$t('COMMON.EXCERPT')"
            v-model="chargePackage.excerpt"
          >
          </el-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </el-form-item>

        <!-- Section Commision -->
        <el-form-item
          class="charge-selection h3 w-100 px-3 mb-3 form-group-wrapper-item w-100"
          :label="`${$t('CHARGES.SELECT_CHARGES')} (*)`"
          prop="charges"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.REQUIRED'),
            },
          ]"
        >
          <charge-selector
            :charge="getPackageIds(chargePackage?.charges)"
            :multiple="true"
            :filterable="true"
            :showAll="false"
            :allowCreate="false"
            :selectedCategory="categoryFee"
            @chargeChanged="feeSelected"
          />
        </el-form-item>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            @click.prevent="checkChargePackageInformation"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              chargePackage.id
                ? $t("CHARGE_PACKAGES.EDIT_CHARGE_PACKAGE")
                : $t("CHARGE_PACKAGES.ADD_CHARGE_PACKAGE")
            }}
          </base-button>
          <button
            v-if="onlyCharges"
            type="button"
            class="btn btn-sm btn-danger btn-cancel ml-2"
            @click="cancelEdition"
            :disabled="loading || processing"
          >
            {{ $t("COMMON.CANCEL") }}
          </button>
        </div>
      </div>
    </el-form>

    <modal
      :show="showChargePackageCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("CHARGE_PACKAGES.SAME_CHARGE_PACKAGES", {
                name: chargePackage.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="chargePackages"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
                <template v-slot="{ row }">
                  {{ row.excerpt ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleChargePackageCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Row,
  Col,
  Radio,
  MessageBox,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import ChargeSelector from "../../ChargeManagement/components/ChargeSelector.vue";
import { CATEGORY_FEE } from "@/constants/charges";
export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    ChargeSelector,
  },

  mixins: [formMixin],

  props: [
    "chargePackageData",
    "formErrors",
    "loading",
    "hideSubmit",
    "onlyCharges",
  ],
  data() {
    let chargePackageData = { ...this.chargePackageData };
    return {
      chargePackage: chargePackageData,
      chargePackages: [],
      total: 0,
      showModal: false,
      showChargePackageCheckModal: false,
      processing: false,
      categoryFee: CATEGORY_FEE,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    feeSelected(selected) {
      this.chargePackage.charges = [...selected].map((item) => ({
        type: "charges",
        id: item,
      }));
    },
    async handleSubmit() {
      const checkUniqueChargePackage =
        await this.checkChargePackageUniqueInformation();
      if (checkUniqueChargePackage) {
        await this.sendInformation();
      } else {
        this.showChargePackageCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.chargePackage.name) {
        this.chargePackage.name = this.chargePackage.name.toString();
      }
      let chargePackageData = cloneDeep(this.chargePackage);
      this.$emit("chargePackageSubmitted", chargePackageData);
    },

    cancelEdition() {
      this.$emit("cancelEdition");
    },

    getPackageIds(charges) {
      if (charges && charges.length) {
        return charges.map((charge) => charge.id);
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.chargePackage?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.chargePackage?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("chargePackages/list", params);
        this.chargePackages = this.$store.getters["chargePackages/list"];
        this.total = this.$store.getters["chargePackages/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkChargePackageUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.chargePackage?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleChargePackageCheckModalState();
      await this.sendInformation();
    },

    async toggleChargePackageCheckModalState() {
      this.showChargePackageCheckModal = !this.showChargePackageCheckModal;
    },

    checkChargePackageInformation() {
      this.$refs["chargePackageForm"].validate(async (valid) => {
        if (valid) {
          if (this.chargePackage.name) {
            this.handleSubmit();
            this.showModal = false;
          } else {
            this.showModal = true;
          }
        }
      });
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    chargePackageData(chargePackageData) {
      if (chargePackageData) {
        this.chargePackage = {
          ...this.chargePackage,
          ...cloneDeep(chargePackageData),
        };
      }
    },
  },
};
</script>

<style>
.add-form .btn-cancel {
  font-size: 14px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}
</style>
