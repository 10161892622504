<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <edit-charge-package-component
      v-if="chargePackage"
      :chargePackageId="chargePackage.id"
      @onViewChargePackage="openChargePackageViewModal"
      only-charges
      @cancelEdition="closeFormModal"
    />
  </modal>
</template>

<script>
import EditChargePackageComponent from "../../components/EditChargePackageComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EditChargePackageComponent,
  },
  props: ["chargePackageData", "showModal"],

  data() {
    let chargePackageData = { ...this.chargePackageData };
    return {
      chargePackage: chargePackageData,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    openChargePackageViewModal() {
      this.$emit("needReload");
      this.closeFormModal();
    },
    closeFormModal() {
      this.$emit("onChangeModalState");
    },
  },

  watch: {
    chargePackageData(chargePackageData) {
      if (chargePackageData) {
        this.chargePackage = {
          ...this.chargePackage,
          ...cloneDeep(chargePackageData),
        };
      }
    },
  },
};
</script>
