<template>
  <div class="container-fluid">
    <charge-package-form
      :loading="loading"
      :chargePackageData="chargePackage"
      :formErrors="formErrors"
      @chargePackageSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultChargePackage from "../defaultChargePackage";
import ChargePackageForm from "../partials/ChargePackageForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ChargePackageForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      chargePackage: cloneDeep(defaultChargePackage),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(chargePackage) {
      this.loading = true;

      const chargePackageData = cloneDeep(chargePackage);
      delete chargePackageData.id;

      try {
        await this.$store.dispatch("chargePackages/add", chargePackageData);
        this.$notify({
          type: "success",
          message: this.$t("CHARGE_PACKAGES.CHARGE_PACKAGE_ADDED"),
        });
        const chargePackage = await this.$store.getters[
          "chargePackages/chargePackage"
        ];
        this.$emit("onViewChargePackage", chargePackage, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
