<template>
  <div class="charges-content">
    <div class="custom-header">
      <h3>{{ $t("COMMON.DETAILS") }}</h3>
      <base-button
        class="elite-button add"
        type="button"
        @click="toggleModalState"
      >
        <span class="btn-inner--icon">
          <i class="far fa-edit"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("CHARGE_PACKAGES.EDIT_CHARGES") }}
        </span>
      </base-button>
    </div>
    <div class="">
      <charges-view-table :charges="chargePackage.charges" />
    </div>

    <charges-form
      :chargePackageData="chargePackage"
      :showModal="showModal"
      @onChangeModalState="toggleModalState"
      @needReload="needReload"
    />
  </div>
</template>

<script>
import ChargesViewTable from "./ChargesViewTable.vue";
import ChargesForm from "./ChargesForm.vue";

export default {
  name: "charges-view",
  props: ["chargePackage"],

  components: {
    ChargesViewTable,
    ChargesForm,
  },

  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    needReload() {
      this.$emit("needReload");
    },
    toggleModalState() {
      this.showModal = !this.showModal;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
